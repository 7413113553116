import React, {
  createContext,
  useState,
  ReactNode,
  useEffect,
} from 'react'

interface AlertContextType {
  showAlert: boolean
  setShowAlert: (value: boolean) => void
}

export const AlertContext = createContext<AlertContextType | false>(
  false
)

interface AlertProviderProps {
  children: ReactNode
}

export const AlertProvider = ({ children }: AlertProviderProps) => {
  const [showAlert, setShowAlert] = useState<boolean>(false)

  // Show alert message for 3 seconds
  useEffect(() => {
    if (showAlert) {
      const timer = setTimeout(() => {
        setShowAlert(false)
      }, 3000) 
      return () => clearTimeout(timer)
    }
  }, [showAlert, setShowAlert])

  return (
    <AlertContext.Provider value={{ showAlert, setShowAlert }}>
      {children}
    </AlertContext.Provider>
  )
}