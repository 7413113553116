import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { Grid } from '@mui/material'
import styled from 'styled-components'
import { FieldErrors, useForm, useWatch } from 'react-hook-form'
import { ControllerTextField } from '../rhf'
import { ControllerTagField } from '../rhf/tag-field'
import { isMobile } from '../../utils/detect-screen'
import { useGlobalStyleContext } from '../../contexts'
import { useAppSelector } from '../../redux/hooks'
import { AlertContext } from '../../contexts/alert-context'
import { displayName } from 'react-quill'
import { ContactsOutlined } from '@mui/icons-material'

interface Props {
  control: any
  errors: FieldErrors
  skillsArray: any
  isSubmitting: boolean
  handleAddSkill: (title: string) => void
  handleRemoveSkill: (index: number) => void
  setIsClicked: (isClicked: boolean) => void
  isClicked: boolean
  onValidateDisplayName: () => void
}

const Length = styled.div`
  text-align: right;
  font-size: 14px;
  font-weight: 500;
  color: ${props => props.theme.gray50};
  display: block;
  text-decoration: none;
  margin-top: -30px;
  margin-right: 15px;
`

export function BasicProfile({
  control,
  errors,
  skillsArray,
  isSubmitting,
  handleAddSkill,
  handleRemoveSkill,
  isClicked,
  setIsClicked,
  onValidateDisplayName,
}: Props): ReactElement {
  const handleDelete = (index: number) => {
    handleRemoveSkill(index)
  }

  const user = useAppSelector(state => state.user)
  const alertContext = useContext(AlertContext)
  if (!alertContext) {
    throw new Error('FieldsErrorAlert must be used within an AlertProvider')
  }
  const { showAlert, setShowAlert } = alertContext
  const { watch } = useForm()
  const { makeAlert } = useGlobalStyleContext()
  const handleAddition = (tag: any) => {
    // Check if the user has reached the limit of 10 skills
    if (skillsArray.length > 10) {
      makeAlert(
        'error',
        'You have already reached the limit of 10 skills for your profile'
      )
    } else {
      // Validate the length of the skill tag (should not be too short or too long)
      if (tag.length <= 2) {
        // Skill is too short, handle accordingly
      } else if (tag.length > 255) {
        // Skill is too long, handle accordingly
      } else {
        // Add the valid skill tag to the skills array
        handleAddSkill(tag.text)
      }
    }
  }
  // Initialize state for text length, retrieving from local storage if available
  const [length, setLength] = useState<number>(() => {
    const savedLength = localStorage.getItem('textLength')
    return savedLength ? parseInt(savedLength) : 0 // Default to 0 if no saved length
  })

  // Update the length state based on the text input's length with a slight delay
  const handleGetLength = (text: any): void => {
    setTimeout(() => {
      setLength(text.target.value.length)
    }, 0)
  }

  // Effect to save the current length to local storage when isClicked is true
  useEffect(() => {
    if (isClicked) {
      localStorage.setItem('textLength', length.toString()) // Save length as a string
    }
  }, [isClicked, length]) // Run effect whenever isClicked or length changes

  // Watch the 'displayName' value once at the top
  const displayNameValue = useWatch({ control, name: 'displayName' })

  useEffect(() => {
    if (isClicked) {
      if (!displayNameValue) {
        onValidateDisplayName() // Trigger alert if displayNameValue is empty/undefined
      }
      setIsClicked(false) // Reset isClicked to prevent re-triggering
    }
  }, [isClicked, displayNameValue, onValidateDisplayName, setIsClicked])

  return (
    <Grid
      container
      spacing={2}
      marginBottom={2}
      flexDirection={isMobile() ? 'column' : 'row'}
    >
      <Grid item md={6} sm={12}>
        <ControllerTextField
          controllerInstance={control}
          controllerName="displayName"
          errors={errors}
          background={'gray70'}
          disabled={isSubmitting}
          label={'Full Name *'}
          placeholder={'John Doe'}
          controllerRules={{
            maxLength: {
              value: 255,
              message: 'Please edit to 255 characters or fewer',
            },
            minLength: {
              value: 3,
              message: 'Please provide at least 3 characters',
            },
            required: {
              value: true,
              message: ' ',
            },
          }}
        />
      </Grid>

      <Grid item md={6} sm={12}>
        <ControllerTextField
          controllerInstance={control}
          controllerName="email"
          errors={errors}
          background={'gray70'}
          disabled={isSubmitting}
          label={'Email Address'}
          placeholder={'my@email.com'}
          controllerRules={{
            maxLength: {
              value: 255,
              message: 'Please edit to 255 characters or fewer',
            },
            minLength: {
              value: 3,
              message: 'Please provide at least 3 characters',
            },
            pattern: {
              value: /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
              message:
                'Please enter a valid email in the format name@example.com.',
            },
          }}
        />
      </Grid>

      <Grid item md={12} sm={12}>
        <ControllerTextField
          controllerInstance={control}
          controllerName="bio"
          errors={errors}
          background={'gray70'}
          disabled={isSubmitting}
          label={'Bio'}
          placeholder={'A small bio ...'}
          multiline
          paddingBottom={'40px'}
          controllerRules={{
            maxLength: {
              value: 500,
              message: 'Please edit to 500 characters or fewer',
            },
            minLength: {
              value: 3,
              message: 'Please provide at least 3 characters',
            },
          }}
          onKeyDown={handleGetLength}
          rows={6}
        />
        <Length>
          <p>{length}/500</p>
        </Length>
      </Grid>

      <Grid
        item
        md={12}
        sm={12}
        style={{ marginTop: '20px', marginBottom: '20px' }}
      >
        <h4>Skills</h4>
      </Grid>

      <Grid item md={12} sm={12} key={1}>
        <ControllerTagField
          controllerInstance={control}
          controllerName={`skills.${skillsArray.length}.title`}
          errors={errors}
          disabled={isSubmitting}
          label={'Title'}
          placeholder={'Skill Title'}
          tags={skillsArray.map((item: any, index: number) => ({
            text: item.title,
            id: index.toString(),
          }))}
          controllerRules={{
            maxLength: {
              value: 500,
              message: 'Please edit to 500 characters or fewer',
            },
            minLength: {
              value: 3,
              message: 'Please provide at least 3 characters',
            },
          }}
          handleAddition={handleAddition}
          handleDelete={handleDelete}
        />
      </Grid>
    </Grid>
  )
}
