export const ROUTES = {
  INDEX: "/",
  PROFILE: "/profile",
  POSTS_NEW: "/posts/new",
  POSTS_DETAILS: "/posts/get/:id",
  POSTS_EDIT: "/posts/edit/:id",
  POSTS_SEARCH: "/posts/search/:tag",
  NOT_FOUND: "/404",
  USER_PROFILE: "/u/:did",
  PUBLIC_CHAT_ROOM: "/chat/public/:id",
  PRIVATE_CHAT_ROOM: "/chat/private/:id",
  ARTICLES_NEW: "/articles/new",
  ARTICLES_PAGE: "/articles",
  ARTICLES_DETAIL: "/articles/get/:id",
  ARTICLES_EDIT: "/articles/edit/:id",
  ARTICLES_SEARCH: "/articles/search/:tag",
  PLATFORMS: "/platforms",
  PLATFORM_PAGE: "/platforms/get/:id",
  STARTUPS: "/startups",
  STARTUP_PAGE: "/startups/get/:id",
  PRIVATE_MOBILE_CHAT: "/chat/mobile",
  PRIVATE_MOBILE_NEW_ROOM: "/chat/mobile/room",
  PRIVATE_NOTIFICATION: "/notification",
};
