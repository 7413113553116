import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { StartupProfileModel } from '../../../models/startup/startup-profile.model'
import { breakpoints } from '../../../config/global-styles'

const SloganStyle = styled.div<{ textcolor: string }>`
  display: flex;
  /* position: relative;
  left: -50px;
  @media only screen and (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.tablet}) {
    position: static;
  } */

  > p {
    font-size: 16px;
    font-weight: 400;
    font-family: Inter;
    color: ${({ theme, textcolor }) => theme[textcolor]};
    line-height: 24px;
  }

  > .icon {
    position: relative;
    left: 10px;
    cursor: pointer;
  }
`

/*
  Renders the slogan of a startup profile, 
  utilizing a styled component for customizable text color. 
  Displays the slogan from the startup data if available.
*/
export interface Props {
  startup: StartupProfileModel
}

const truncateSlogan = (slogan: string) => {
  if (slogan.length <= 20) return slogan

  let truncated = slogan.slice(0, 20).trimEnd()
  return `${truncated}...`
}

export function Slogan(props: Props) {
  const { startup } = props

  const processedSlogan = startup?.slogan ? truncateSlogan(startup.slogan) : ''

  return (
    <>
      <SloganStyle textcolor={'white100'}>
        <p>{processedSlogan}</p>
      </SloganStyle>
    </>
  )
}
