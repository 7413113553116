import { useNavigate, useParams } from 'react-router-dom'
import React, { ReactElement, useEffect, useState } from 'react'
import { Card, Page } from '../../components/structure'
import { CButton } from '../../components/mui'
import { PostBox } from '../../components/post/box'
import { NothingFound } from '../../components/custom'
import { findAllPosts } from '../../apis/post.apis'
import { PostModel } from '../../models/post.model'
import InfiniteScroll from 'react-infinite-scroll-component'
import { isDesktop, isMobile } from '../../utils/detect-screen'

export function SearchPostPage(): ReactElement {
  const { tag } = useParams()
  const [loading, setLoading] = useState<boolean>(true)
  const [posts, setPosts] = useState<Array<PostModel>>([])
  const [noMoreData] = useState<boolean>(false)
  const [paginationLoading, setPaginationLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const [cursor, setCursor] = useState<string>('')
  const [hasMore, setHasMore] = useState<boolean>(true)

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
    fetchAllPosts('')
  }, [])

  const fetchAllPosts = (_cursor: string) => {
    setLoading(true)

    findAllPosts({
      numberPerPage: 5,
      cursor: _cursor,
      search: { q: tag, profileIDs: [''] },
    })
      .then(res => {
        if (res && res.data.posts.length > 0) {
          setPosts(prevPosts => [...prevPosts, ...res.data.posts])

          // Check if the cursor has not changed or fewer posts are returned
          if (!res.data.cursor || res.data.posts.length < 5) {
            setHasMore(false) // No more data
          } else {
            setCursor(res.data.cursor)
          }
        } else {
          setHasMore(false) // No posts returned
        }

        setPaginationLoading(false)
        setLoading(false)
      })
      .catch(err => {
        console.error('API Error:', err)
        setLoading(false)
      })
  }

  return (
    <Page
      title={'Post By Tag Page'}
      sidebar={
        <>
          <div className={'back'}>
            <CButton
              size={'s'}
              background={'navy100'}
              backgroundHover={'navy100'}
              backgroundDisabled={'navy100'}
              color={'white100'}
              onClick={() => navigate(-1)}
              startIcon={'keyboard_arrow_left'}
            >
              <span style={{ marginLeft: '5px' }}>Back</span>
            </CButton>
          </div>
        </>
      }
      sidebar2={<></>}
    >
      <Card title={'Posts by tag: ' + tag}>
        <div id="scrollableDiv">
          {loading ? (
            [1, 2, 3, 4].map(i => (
              <PostBox loading={loading} key={i} type={2} />
            ))
          ) : posts.length === 0 ? (
            <NothingFound
              icon="hourglass_disabled"
              title="No Posts Found"
              padding={'30px'}
            />
          ) : (
            <InfiniteScroll
              style={{ display: 'flex', flexDirection: 'column' }}
              dataLength={posts.length}
              next={() => fetchAllPosts(cursor)}
              hasMore={hasMore}
              loader={
                <p
                  style={{
                    textAlign: 'center',
                    color: 'white',
                    padding: '20px',
                    background: '#160f2b',
                    borderRadius: '8px',
                    fontSize: '13px',
                  }}
                >
                  <b>Loading ...</b>
                </p>
              }
              endMessage={
                <p
                  style={(isMobile || isDesktop) ? {
                    textAlign: 'center',
                    color: 'white',
                    padding: '20px',
                    background: '#160f2b',
                    borderRadius: '8px',
                    fontSize: '13px',
                  } : null}
                >
                  <b>No More Posts</b>
                </p>
              }
            >
              <div>
                {posts.map((post, i) => {
                  return (
                    <PostBox loading={loading} post={post} key={i} type={2} />
                  )
                })}
              </div>
            </InfiniteScroll>
          )}
        </div>
      </Card>
    </Page>
  )
}
