import { CButton } from '../mui'
import { useEffect, useRef, useState } from 'react'
import { CModalTwo } from '../mui/ModalTwo'
import { LoginChildSec } from '../newStructures/LoginChild.style'
import { LoginWalletConnect } from './login-wallet-connect'
import { LoginButtonQr } from './login-qr'
import { isDesktop } from '../../utils/detect-screen'
import { ReactComponent as CloseLogin } from '../../assets/svg/close-login.svg'
import { CSvgButton } from '../mui/SvgButton'
import { io } from 'socket.io-client'

interface Props {
  color: string
  textcolor: string
  size: 's' | 'm' | 'l'
  isOpen?: boolean
}

export function LoginButton(props: Props) {
  const { color, textcolor, size, isOpen } = props
  const [loginState, setLoginState] = useState<boolean>(false)
  const ref = useRef()
  const closeLoginModal = () => {
    setLoginState(false)
  }

  /* 
Initiates the login process by updating the login state when the modal is opened.
*/

  const startLogin = () => {
    setLoginState(true)
  }

  useEffect(() => {
    if (isOpen) {
      startLogin()
    }
  }, [isOpen])

  return (
    <>
      <LoginWalletConnect
        setMainLoginState={(state: boolean) => setLoginState(state)}
        textColor={textcolor}
        size={size}
        background={color}
        startIcon={'login'}
        loadingColor={'green100'}
        fullWidth={true}
        backgroundHover={color}
        color={textcolor}
        text={'Login'}
      />
    </>
  )
}
