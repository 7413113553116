import React, { useEffect } from "react";
import { ChatBox } from "../../../components/chat";
import { Page } from "../../../components/structure";
import { useNavigate } from "react-router-dom";
import { isDesktop } from "../../../utils/detect-screen";

type Props = {};

const MobileChatPage = (props: Props) => {
  const navigate = useNavigate()
  /* 
Navigates to the home page when the component mounts on a desktop device:
- Checks if the current device is a desktop using the `isDesktop()` function.
- If true, it redirects the user to the home page (`/`) using `navigate`.
- This effect runs only once on component mount due to the empty dependency array (`[]`).
*/
  useEffect(() => {
    if (isDesktop()) {
      navigate('/')
    }
  }, [])
  return (
    <Page title="Personia" sidebar={<></>} sidebar2={<></>} mainHeight="500px">
      <ChatBox mobileChat={true} />
    </Page>
  )
};

export default MobileChatPage;
