import { useAppSelector } from '../../redux/hooks'
import React, { ReactElement, useEffect, useState } from 'react'
import { Page } from '../../components/structure'
import styled from 'styled-components'
import '../../assets/editor.css'
import { Link, useNavigate, useParams } from 'react-router-dom'
import { CButton, CSkeleton, CTab, CTabs } from '../../components/mui'
import { Banner, Logo, Name, Slogan } from '../../components/startup/header'
import { PlatformName } from '../../components/startup/header'
import { PlatformPostModel } from '../../models/platform/platform-post.model'
import { StartupProfileModel } from '../../models/startup/startup-profile.model'
import { PlatformProfileModel } from '../../models/platform/platform-profile.model.'
import { start } from 'repl'
import { findSingleStartup } from '../../apis/startup.apis'
import { findSinglePlatform } from '../../apis/platform.apis'
import { breakpoints } from '../../config/global-styles'
import { isDesktop } from '../../utils/detect-screen'

const Box = styled.div`
  > .row-1 {
    width: 100%;
    height: 315px;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.white80};
    top: 60px;
    @media only screen and (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.tablet}) {
      position: static;
      .flex {
        width: 100% !important;
        flex-wrap: wrap;
      }
    }
    > .flex {
      display: flex;
      position: relative;
      gap: 20px;
      @media only screen and (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.tablet}) {
        gap: 0px;
      }

      > .flex-content {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        height: 100%;
        padding: 10px;
        @media only screen and (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.tablet}) {
          justify-content: center;
          align-items: center;
        }
      }

      > div {
        > * {
          margin-bottom: 0.3rem;
        }
      }

      > .right {
        position: absolute;
        right: 20px;
        top: 20px;
        float: right;
        vertical-align: center;
      }
    }
  }

  > .row-2 {
    > .body {
      padding: 20px;
      background: ${props => props.theme.navy80};

      > .text {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 22px;

        > div:last-child {
          position: relative;
        }

        p,
        span,
        div {
          color: ${props => props.theme.white100};
        }

        > h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        ul,
        li,
        ol {
          color: ${props => props.theme.white100};
        }

        > a {
          color: ${props => props.theme.green100};
        }

        > img {
          margin-top: 20px;
        }

        > div:nth-of-type(1) {
          font-size: 38px;
          font-weight: 600;
          line-height: 46px;
          font-style: normal;
        }

        > p {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
        }

        > div {
          .detail3 {
            font-size: 14px;
            font-weight: 500;
            margin-top: 8px;
            color: #ffffffa6;
          }
          .detail2 {
            font-size: 14px;
            font-weight: 500;
            margin-top: 8px;
            color: #1677ff;
          }
          > * {
            display: flex;
            width: 100%;
            height: auto;
            align-self: stretch;
            margin: -0.1rem 0;
          }

          > span:nth-of-type(1) {
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.15px;
          }

          > a {
            color: #1677ff;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.5px;
          }

          > span:nth-of-type(2) {
            color: rgba(255, 255, 255, 0.65);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.5px;
          }
        }
      }
    }
  }
`

const TabBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
  position: sticky;
  top: 0;
  z-index: 20;
  background: ${props => props.theme.navy80};
  border-radius: 16px;

  > span {
    margin-right: 15px;
    font-size: 14px;
    font-weight: 500;
    color: ${props => props.theme.black80};
  }
`

export function StartupDetailPage(): ReactElement {
  const user = useAppSelector(state => state.user)

  /* 
  Scrolls the window to the top-left corner smoothly when the component mounts. 
  This effect runs only once due to the empty dependency array. 
  */
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])
  const { id } = useParams()
  const navigate = useNavigate()
  const [tab, setTab] = useState<'posts' | 'articles' | 'journey' | 'about'>(
    'journey'
  )
  const [platform, setPlatform] = useState<PlatformPostModel>()
  const [platformModel, setPlatformModel] = useState<PlatformProfileModel>()
  const [startup, setStartup] = useState<StartupProfileModel>(null)
  const [startupLoading, setStartupLoading] = useState<boolean>(false)

  /* 
  Handles the change of the view type by updating the tab state 
  with the newly selected value from the event. 
  */
  const handleChangeViewType = (event: any, newValue: any): void => {
    setTab(newValue)
  }

  /* 
  Fetches startup data based on the provided ID. 
  Sets the loading state while fetching, updates the startup state with the fetched data, 
  and handles navigation to the startups page in case of an error.
  */
  useEffect(() => {
    const fetchStartupData = async () => {
      try {
        setStartupLoading(true)
        const startupResult = await findSingleStartup(id ?? '')

        setStartup(startupResult.data.startup)

        // If the startup has a platform ID, fetch the platform profile
        // if (startupResult.data.) {
        //   const platformResult = await findSinglePlatform(id ?? '')
        //   setPlatform(platformResult.data.platform) // Update the platform state
        // }
        setStartupLoading(false)
      } catch (error) {
        setStartupLoading(false)
        navigate('/startups')
      }
    }

    fetchStartupData()
  }, [id])

  return (
    <Page
      title="Startup"
      sidebar2={
        <>
          <div className={'back'}>
            <CButton
              size={'s'}
              background={'navy100'}
              backgroundHover={'navy100'}
              backgroundDisabled={'navy100'}
              color={'white100'}
              onClick={() => navigate(-1)}
              startIcon={'keyboard_arrow_left'}
            >
              <span style={{ marginLeft: '5px' }}>Back</span>
            </CButton>
          </div>
        </>
      }
    >
      <Box>
        {startupLoading ? (
          <>
            <div className={'row-1'}>
              <CSkeleton width={'100%'} borderradius={'8px'} />
              <div className={'flex'}>
                <CSkeleton
                  width={'162px'}
                  height={'162px'}
                  borderradius={'1000px'}
                  margintop={'-80px'}
                  marginLeft={'16px'}
                />
                <div>
                  <CSkeleton
                    width={200}
                    height={10}
                    marginbottom={'5px'}
                    marginLeft={'16px'}
                    margintop={'20px'}
                  />
                  <CSkeleton
                    width={200}
                    height={10}
                    marginbottom={'5px'}
                    marginLeft={'16px'}
                  />
                </div>
              </div>
            </div>
            <div className={'row-2'}>
              <TabBox>
                <CTabs
                  value={tab}
                  key={1}
                  $padding={'20px'}
                  $background={'navy60'}
                  $activeBG={'navy60'}
                  onChange={handleChangeViewType}
                >
                  <CTab
                    label={'Journey'}
                    id={'view-tab-journey'}
                    aria-controls={'view-tabpanel-journey'}
                    value={'journey'}
                    disableTouchRipple
                    $fullWidth
                  />
                  <CTab
                    label={'About'}
                    id={'view-tab-about'}
                    aria-controls={'view-tabpanel-about'}
                    value={'about'}
                    $fullWidth
                  />
                </CTabs>
              </TabBox>
              <div className={'body'}>
                {tab === 'journey' && (
                  <CSkeleton
                    width={'90%'}
                    height={10}
                    marginbottom={'20px'}
                    marginLeft={'16px'}
                    margintop={'20px'}
                    marginRight={'16px'}
                  />
                )}
                {tab === 'about' && (
                  <CSkeleton
                    width={'90%'}
                    height={10}
                    marginbottom={'20px'}
                    marginLeft={'16px'}
                    margintop={'20px'}
                    marginRight={'16px'}
                  />
                )}
              </div>
            </div>
          </>
        ) : (
          startup && (
            <>
              <div className={'row-1'}>
                <Banner startup={startup} />
                <div className={'flex'}>
                  <Logo startup={startup} />
                  <div
                    className="flex-content"
                    style={isDesktop() ? null : { width: '100%' }}
                  >
                    <Name startup={startup} />
                    <Slogan startup={startup} />
                    <PlatformName startup={startup} platform={platformModel} />
                  </div>
                </div>
              </div>
              <div className={'row-2'}>
                <TabBox>
                  <CTabs
                    value={tab}
                    key={1}
                    $padding={'20px'}
                    $background={'navy60'}
                    $activeBG={'navy60'}
                    onChange={handleChangeViewType}
                  >
                    <CTab
                      label={'Journey'}
                      id={'view-tab-journey'}
                      aria-controls={'view-tabpanel-journey'}
                      value={'journey'}
                      disableTouchRipple
                      $fullWidth
                    />
                    <CTab
                      label={'About'}
                      id={'view-tab-about'}
                      aria-controls={'view-tabpanel-about'}
                      value={'about'}
                      disableTouchRipple
                      $fullWidth
                    />
                  </CTabs>
                </TabBox>

                <div className={'body'}>
                  {tab === 'journey' && (
                    <div className={'text'}>
                      <div>{startup.projectVision}</div>
                      <div className={'editor'}>
                        <div
                          className={'ql-container ql-snow'}
                          style={{ height: 'auto', padding: '0px' }}
                        >
                          <div
                            className={'ql-editor'}
                            style={{padding: '0px'}}
                            dangerouslySetInnerHTML={{
                              __html: decodeURIComponent(
                                startup.projectMission
                              ),
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  )}
                  {tab === 'about' && (
                    <div className="text">
                      <div>Overview</div>
                      <p>{startup.overview}</p>
                      <div>
                        <span>Website</span>
                        <a href="https://embodia.io/">{startup.website}</a>
                      </div>
                      <div>
                        <span>Phone Number</span>
                        {isDesktop() ? (
                          <p className="detail3">{startup.phoneNumber}</p>
                        ) : (
                          <a
                            href={`tel:${startup.phoneNumber}`}
                            className="detail2"
                          >
                            {startup.phoneNumber}
                          </a>
                        )}
                      </div>
                      <div>
                        <span>Address</span>
                        <span>{startup.address}</span>
                      </div>
                      <div>
                        <span>Company Size</span>
                        <span>{startup.companySize}</span>
                      </div>
                      <div>
                        <span>Founded</span>
                        <span>
                          {new Date(
                            startup.fundingStartDate
                          ).toLocaleDateString()}
                        </span>
                      </div>
                      <div>
                        <span>Specialties</span>
                        <span>{startup.specialties[0]}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          )
        )}
      </Box>
    </Page>
  )
}
