import { useAppSelector } from '../../redux/hooks'
import React, { ReactElement, useEffect, useState } from 'react'
import { Page } from '../../components/structure'
import styled from 'styled-components'
import '../../assets/editor.css'
import {
  Link,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from 'react-router-dom'
import { CButton, CSkeleton, CTab, CTabs } from '../../components/mui'
import { Banner, Logo, Name, Slogan } from '../../components/platform/header'
import { PlatformProfileModel } from '../../models/platform/platform-profile.model.'
import { PlatformData } from '../../components/platform/header/platformData'
import { isDesktop, isTablet } from '../../utils/detect-screen'
import { ProfileBox } from '../../components/profile'
import { findSinglePlatform } from '../../apis/platform.apis'
import { breakpoints } from '../../config/global-styles'
import { StartupProfileModel } from '../../models/startup/startup-profile.model'

const Box = styled.div`
  > .row-1 {
    width: 100%;
    height: 315px;
    border-radius: 8px;
    border: 1px solid ${({ theme }) => theme.white80};
    top: 60px;
    @media only screen and (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.tablet}) {
      position: static;
      .flex {
        width: 100% !important;
        flex-wrap: wrap;
      }
    }

    > .flex {
      display: flex;
      position: relative;
      gap: 20px;
      @media only screen and (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.tablet}) {
        gap: 0px;
      }
      > .flex-content {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
        height: 100%;
        padding: 10px;
        @media only screen and (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.tablet}) {
          justify-content: center;
          align-items: center;
        }
      }

      > div {
        > * {
          margin-bottom: 0.3rem;
        }
      }

      > .right {
        position: absolute;
        right: 20px;
        top: 20px;
        float: right;
        vertical-align: center;
      }
    }
  }

  > .row-2 {
    > .body {
      padding: 20px;
      background: ${props => props.theme.navy80};

      > .text {
        position: relative;
        display: flex;
        flex-direction: column;
        gap: 22px;

        > div:last-child {
          position: relative;
        }

        p,
        span,
        div {
          color: ${props => props.theme.white100};
        }

        > h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        ul,
        li,
        ol {
          color: ${props => props.theme.white100};
        }

        > a {
          color: ${props => props.theme.green100};
        }

        > img {
          margin-top: 20px;
        }

        > div:nth-of-type(1) {
          font-size: 38px;
          font-weight: 600;
          line-height: 46px;
          font-style: normal;

          @media only screen and (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.tablet}) {
            font-size: 20px;
          }
        }

        > p {
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          line-height: 22px;
        }

        > div {
          .detail3 {
            font-size: 14px;
            font-weight: 500;
            margin-top: 8px;
            color: #ffffffa6;
          }
          .detail2 {
            font-size: 14px;
            font-weight: 500;
            margin-top: 8px;
            color: #1677ff;
          }
          > * {
            display: flex;
            width: 100%;
            height: auto;
            align-self: stretch;
            margin: -0.1rem 0;
          }

          > span:nth-of-type(1) {
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 24px;
            letter-spacing: 0.15px;
          }

          > a {
            color: #1677ff;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.5px;
          }

          > span:nth-of-type(2) {
            color: rgba(255, 255, 255, 0.65);
            font-size: 14px;
            font-style: normal;
            font-weight: 400;
            line-height: 24px;
            letter-spacing: 0.5px;
          }
        }
      }
    }
  }
`

const TabBox = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-top: 15px;
  position: sticky;
  top: 0;
  z-index: 20;
  background: ${props => props.theme.navy80};
  border-radius: 16px;

  > span {
    margin-right: 15px;
    font-size: 14px;
    font-weight: 500;
    color: ${props => props.theme.black80};
  }
`

export function PlatformDetailPage(): ReactElement {
  /* 
  Scrolls the window to the top-left corner smoothly when the component mounts. 
  This effect runs only once due to the empty dependency array. 
  */
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: 'smooth',
    })
  }, [])

  const { id } = useParams()
  const [platform, setPlatform] = useState<PlatformProfileModel>()
  const [startupModel, setStartupModel] = useState<StartupProfileModel>()
  const [platformLoading, setPlatformLoading] = useState<boolean>(false)
  const navigate = useNavigate()
  const [tab, setTab] = useState<'posts' | 'articles' | 'journey' | 'about'>(
    'journey'
  )

  /* Fetches platform data when the component mounts, updating the platform state on success. */
  useEffect(() => {
    findSinglePlatform(id)
      .then(result => {
        setPlatform(result.data.platform)
      })
      .catch(error => {})
  }, [])

  /* 
  Handles the change of the view type by updating the tab state 
  with the newly selected value from the event. 
  */
  const handleChangeViewType = (event: any, newValue: any): void => {
    setTab(newValue)
  }

  return (
    <Page
      title="Platform"
      sidebar2={
        <>
          <div className={'back'}>
            <CButton
              size={'s'}
              background={'navy100'}
              backgroundHover={'navy100'}
              backgroundDisabled={'navy100'}
              color={'white100'}
              onClick={() => navigate(-1)}
              startIcon={'keyboard_arrow_left'}
            >
              <span style={{ marginLeft: '5px' }}>Back</span>
            </CButton>
          </div>
        </>
      }
    >
      <Box>
        {platformLoading ? (
          <>
            <div className="row-1">
              <CSkeleton width="100%" borderradius="8px" />
              <div className="flex">
                <CSkeleton
                  width="162px"
                  height="162px"
                  borderradius="1000px"
                  margintop="-80px"
                  marginLeft="16px"
                />
                <div>
                  <CSkeleton
                    width={200}
                    height={10}
                    marginbottom="5px"
                    marginLeft="16px"
                    margintop="20px"
                  />
                  <CSkeleton
                    width={200}
                    height={10}
                    marginbottom="5px"
                    marginLeft="16px"
                  />
                </div>
              </div>
            </div>
            <div className="row-2">
              <TabBox>
                <CTabs
                  value={tab}
                  key={1}
                  $padding="20px"
                  $background="navy60"
                  $activeBG="navy60"
                  onChange={handleChangeViewType}
                >
                  <CTab
                    label="Journey"
                    id="view-tab-journey"
                    aria-controls="view-tabpanel-journey"
                    value="journey"
                    disableTouchRipple
                    $fullWidth
                  />
                  <CTab
                    label="about"
                    id="view-tab-about"
                    aria-controls="view-tabpanel-about"
                    value="about"
                    disableTouchRipple
                    $fullWidth
                  />
                </CTabs>
              </TabBox>
              <div className="body">
                {tab === 'journey' && (
                  <CSkeleton
                    width="90%"
                    height={10}
                    marginbottom="20px"
                    marginLeft="16px"
                    margintop="20px"
                    marginRight="16px"
                  />
                )}
                {tab === 'about' && (
                  <CSkeleton
                    width="90%"
                    height={10}
                    marginbottom="20px"
                    marginLeft="16px"
                    margintop="20px"
                    marginRight="16px"
                  />
                )}
              </div>
            </div>
          </>
        ) : (
          platform && (
            <>
              <div className="row-1">
                <Banner platform={platform} />
                <div className="flex">
                  <Logo platform={platform} />
                  <div
                    className="flex-content"
                    style={isDesktop() ? null : { width: '100%' }}
                  >
                    <Name platform={platform} />
                    <Slogan platform={platform} />
                    <PlatformData platform={platform} startup={startupModel} />
                  </div>
                </div>
              </div>
              <div className="row-2">
                <TabBox>
                  <CTabs
                    value={tab}
                    key={1}
                    $padding="20px"
                    $background="navy60"
                    $activeBG="navy60"
                    onChange={handleChangeViewType}
                  >
                    <CTab
                      label="Journey"
                      id="view-tab-journey"
                      aria-controls="view-tabpanel-journey"
                      value="journey"
                      disableTouchRipple
                      $fullWidth
                    />
                    <CTab
                      label="About"
                      id="view-tab-about"
                      aria-controls="view-tabpanel-about"
                      value="about"
                      disableTouchRipple
                      $fullWidth
                    />
                  </CTabs>
                </TabBox>
                <div className="body">
                  {tab === 'journey' && (
                    <div className="text">
                      <div>{platform.projectVision}</div>
                      <div className="editor">
                        <div
                          className="ql-container ql-snow"
                          style={{ height: 'auto', padding: '0px' }}
                        >
                          <div
                            className="ql-editor"
                            style={{padding: '0px'}}
                            dangerouslySetInnerHTML={{
                              __html: decodeURIComponent(
                                platform.projectMission
                              ),
                            }}
                          ></div>
                        </div>
                      </div>
                    </div>
                  )}
                  {tab === 'about' && (
                    <div className="text">
                      <div>Overview</div>
                      <p>{platform.overview}</p>
                      <div>
                        <span>Website</span>
                        <a href="https://embodia.io/">{platform.website}</a>
                      </div>
                      <div>
                        <span>Phone Number</span>
                        {isDesktop() ? (
                          <p className="detail3">{platform.phoneNumber}</p>
                        ) : (
                          <a
                            href={`tel:${platform.phoneNumber}`}
                            className="detail2"
                          >
                            {platform.phoneNumber}
                          </a>
                        )}
                      </div>
                      <div>
                        <span>Address</span>
                        <span>{platform.address}</span>
                      </div>
                      <div>
                        <span>Company Size</span>
                        <span>{platform.companySize}</span>
                      </div>
                      <div>
                        <span>Founded</span>
                        <span>
                          {new Date(
                            platform.fundingStartDate
                          ).toLocaleDateString()}
                        </span>
                      </div>
                      <div>
                        <span>Specialties</span>
                        <span>{platform.specialties[0]}</span>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </>
          )
        )}
      </Box>
    </Page>
  )
}
