import { useGlobalContext } from '../../contexts'
import { CButton } from '../mui'
import { io } from 'socket.io-client'
import { v4 as uuid } from 'uuid'
import { useEffect, useState } from 'react'
import { loginCheck, loginPersonia } from '../../apis/auth.apis'
import { ApiErrorData } from '../../apis/http.api'
import { UserModel } from '../../models/user.model'
import { CModalTwo } from '../mui/ModalTwo'
import { Welcome } from './login-wallet-connect-steps/welcome'
import { SignupQuestion } from './login-wallet-connect-steps/signup-question'
import { Signup } from './login-wallet-connect-steps/signup'
import { Loading } from './login-wallet-connect-steps/loading'
import WalletIframe from './login-wallet-connect-steps/walletIframe'
import { ProfileModel } from '../../models/profile.model'
import { LastWelcomePart } from './login_steps/LastWelcomePart'
import { isDesktop } from '../../utils/detect-screen'
import { CSvgButton } from '../mui/SvgButton'

interface Props {
  color: string
  background: string
  textColor: string
  size: string
  setMainLoginState: (state: boolean) => void
  startIcon: string
  loadingColor: string
  fullWidth: boolean
  backgroundHover: string
  text: string
}

let socket = undefined

export function LoginWalletConnect(props: Props) {
  const { color, textColor, size, backgroundHover, background } = props

  const [loginState, setLoginState] = useState<boolean>(false)
  const [randomUuid, setUuid] = useState<string>(uuid())
  const [user, setUser] = useState<UserModel>()
  const [loading, setLoading] = useState(false)
  const [loginStep, setLoginStep] = useState<
    | 'welcome'
    | 'signup-question'
    | 'signup'
    | 'none'
    | 'loading'
    | 'walletIframe'
    | 'welcome-final'
  >('none')
  const [newLogin, setNewLogin] = useState(false)
  const [wallet, setWallet] = useState<string>('')
  const [did, setDid] = useState<string>('')
  const [tempUser, setTempUser] = useState<ProfileModel>()

  /* 
Handles the socket connection when the client joins:
- The `onConnect` function is called to emit a `join` event to the socket server.
- The payload for the `join` event contains a `randomString`, which is a dynamically generated string using the prefix `'login-'` and a `randomUuid` (likely a unique identifier).
- This function triggers the server to associate the client with a specific session or connection, using the provided random string for identification.
*/
  function onConnect() {
    socket.emit('join', { randomString: 'login-' + randomUuid })
  }

  /* 
Handles the disconnection event:
- The `onDisconnect` function is triggered when the socket disconnects or the user logs out.
- It resets the login state by setting `setLoginStep` to `'none'`, effectively clearing any login-related steps or processes.
- It also sets `setLoading(false)` to stop any loading indicators associated with the disconnection process.
- Finally, `setLoginState(false)` updates the login state to `false`, marking the user as logged out or disconnected.
*/
  function onDisconnect() {
    setLoginStep('none')
    setLoading(false)
    setLoginState(false)
  }

  /* 
Handles the login process: stores the session token, checks the session with `loginCheck`, 
fetches user data, and sets the login step accordingly. If user data exists, it proceeds to 
welcome the user, otherwise asks for signup.
*/
  function onLogin(value: any) {
    if (value.session) {
      setLoginStep('loading')
      localStorage.setItem('token', value.session)
      setLoading(true)
      loginCheck()
        .then(result => {
          setDid(result.data.did.pkh)
          setWallet(result.data.wallet)
          if (result.data.user) {
            loginPersonia()
              .then(result2 => {
                setUser(result2.data.user)
                setLoginStep('welcome')
                setLoading(false)
              })
              .catch(error => {
                setLoading(false)
              })
            socket.emit('disconnect', {})
          } else {
            setLoading(false)
            setLoginStep('signup-question')
          }
        })
        .catch((error: ApiErrorData) => {
          setLoading(false)
        })
    }
  }

  function onJoinEvent(value: any) {
    console.log('join event', value)
  }

  /* 
Sets up socket connection for login: listens for connection, disconnect, join, and walletLogin events.
Cleans up the event listeners on component unmount or when dependencies change.
*/

  useEffect(() => {
    if ((newLogin || loginState) && randomUuid) {
      socket = io(process.env.REACT_APP_API_BASE_URL)
      setNewLogin(false)
      setLoginStep('walletIframe')

      socket.on('connect', onConnect)
      socket.on('disconnect', onDisconnect)
      socket.on('join', onJoinEvent)
      socket.on('walletLogin', onLogin)

      return () => {
        socket.off('connect', onConnect)
        socket.off('disconnect', onDisconnect)
        socket.off('join', onJoinEvent)
        socket.off('walletLogin', onLogin)
      }
    }
  }, [newLogin, loginState, randomUuid, socket])

  /* 
Starts the login process by setting the step to 'walletIframe' and updating login state.
Closes the login modal and resets the login state and loading flag.
*/

  const startLogin = () => {
    setLoginStep('walletIframe')
    setLoginState(true)
  }

  const closeLoginModal = () => {
    setLoginStep('none')
    setLoading(false)
    setLoginState(false)
  }

  return (
    <>
      {loginState && (
        <CModalTwo
          open={loginState}
          onClose={closeLoginModal}
          // title="login modal"
          width="755"
          height="512"
          background={isDesktop() ? 'white' : '#140E26'}
        >
          {isDesktop() ? (
            <div
              style={{
                width: '100%',
                height: '512px',
                display: 'flex',
                background: '#140E26',
              }}
            >
              <img
                loading={'lazy'}
                src={require('../../assets/images/cover.png')}
                alt=""
                width="60%"
                height="100%"
                style={{
                  objectFit: 'cover',
                  borderRadius: '8px 0px 0px 8px',
                }}
              />
              <div style={{ height: '512px' }}>
                {loginStep === 'walletIframe' && (
                  <WalletIframe randomString={randomUuid} />
                )}
                {loginStep === 'loading' && (
                  <Loading onClose={closeLoginModal} />
                )}
                {loginStep === 'welcome' && (
                  <Welcome
                    loading={loading}
                    user={user}
                    onClose={closeLoginModal}
                    wallet={wallet}
                    did={did}
                  />
                )}
                {loginStep === 'signup-question' && (
                  <SignupQuestion
                    loading={loading}
                    onClose={closeLoginModal}
                    setLoginStep={setLoginStep}
                    setNewLogin={setNewLogin}
                  />
                )}
                {loginStep === 'signup' && (
                  <Signup
                    wallet={wallet}
                    did={did}
                    setTempUser={setTempUser}
                    setLoginStep={setLoginStep}
                    onClose={closeLoginModal}
                  />
                )}
                {loginStep === 'welcome-final' && (
                  <LastWelcomePart
                    color="#39DBB2"
                    textColor="#140E26"
                    setLoginState={setLoginState}
                    tempUser={tempUser}
                  />
                )}
              </div>
            </div>
          ) : (
            <>
              {loginStep === 'walletIframe' && (
                <WalletIframe randomString={randomUuid} />
              )}
              {loginStep === 'loading' && <Loading />}
              {loginStep === 'welcome' && (
                <Welcome
                  loading={loading}
                  user={user}
                  onClose={closeLoginModal}
                  wallet={wallet}
                  did={did}
                />
              )}
              {loginStep === 'signup-question' && (
                <SignupQuestion
                  loading={loading}
                  onClose={closeLoginModal}
                  setLoginStep={setLoginStep}
                  setNewLogin={setNewLogin}
                />
              )}
              {loginStep === 'signup' && (
                <Signup
                  wallet={wallet}
                  did={did}
                  setTempUser={setTempUser}
                  setLoginStep={setLoginStep}
                />
              )}
              {loginStep === 'welcome-final' && (
                <LastWelcomePart
                  color="#39DBB2"
                  textColor="#140E26"
                  setLoginState={setLoginState}
                  tempUser={tempUser}
                />
              )}
            </>
          )}
        </CModalTwo>
      )}
      {isDesktop() ? (
        <CButton
          fullWidth
          size={'m'}
          background={background}
          backgroundHover={backgroundHover}
          color={textColor}
          loadingColor={textColor}
          onClick={startLogin}
        >
          <p style={{ marginLeft: '10px', color: textColor }}>
            Connect Your Wallet To Start
          </p>
        </CButton>
      ) : (
        <CButton
          fullWidth
          size={'s'}
          background={background}
          backgroundHover={backgroundHover}
          color={textColor}
          loadingColor={textColor}
          onClick={startLogin}
          maxwidth="115px"
        >
          <p
            style={{
              color: textColor,
              fontSize: '12px',
              width: '100%',
              wordSpacing: 'noWrap',
            }}
          >
            Connect Wallet
          </p>
        </CButton>
      )}

      {/* <CButton
        fullWidth
        size={'m'}
        background={background}
        backgroundHover={backgroundHover}
        color={textColor}
        loadingColor={textColor}
        onClick={startLogin}
      >
        <p style={{ marginLeft: '10px', color: textColor }}>
          Connect Your Wallet To Start
        </p>
      </CButton> */}
    </>
  )
}
