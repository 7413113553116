import React, { ReactElement, useEffect, useRef, useState } from 'react'
import styled from 'styled-components'
import { CTab, CTabs } from '../mui'
import { MyInput } from '../custom/input'
import { Grid } from '@mui/material'
import { CLoader, NothingFound } from '../custom'
import { SearchUserResultBox } from '../search/user-result-box'
import { SearchArticleResultBox, SearchPostResultBox } from '../search'
import { Link } from 'react-router-dom'
import { breakpoints } from '../../config/global-styles'
import { search } from '../../apis/search.api'
import { PostModel } from '../../models/post.model'
import { ProfileModel } from '../../models/profile.model'
import { ArticleModel } from '../../models/article.model'

const Box = styled.div<{ $marginbottom: string; left: number }>`
  background: ${props => props.theme.navy80};
  border-radius: 8px;
  margin-bottom: ${({ $marginbottom }) => $marginbottom};
  width: 600px;
  position: absolute;
  top: 60px;
  z-index: 100;
  margin-left: 130px;

  .body {
    padding: 10px;
    display: block;
    position: relative;

    .view-all {
      margin-right: 15px;
      font-size: 14px;
      font-weight: 500;
      color: ${props => props.theme.green100};
      position: absolute;
      right: 0;
      text-decoration: none;
    }

    > .title-box {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      gap: 1rem;

      > .tag {
        display: flex;
        gap: 8px;
        align-items: center;
        color: white;
        > .tag-before {
          width: 16px;
          height: 5px;
          border-radius: 50px;
          background-color: ${({ theme }) => theme.green100};
        }
      }
      a {
        font-size: 14px;
        color: ${({ theme }) => theme.green100};
        text-decoration: none;
      }
    }
  }
`

export interface Props {
  title?: string
  setIsOpenSearch?: any
}

export function SearchBox(props: Props): ReactElement {
  const [viewType, setViewType] = useState(0)
  const [value, setValue] = useState<string>('')

  /* 
Detects clicks outside the referenced element and resets search state:
- Adds a `mousedown` event listener to detect clicks outside the `ref` element.
- If a click is detected outside, clears `posts`, `users`, and `articles` data, and closes the search modal by calling `props.setIsOpenSearch(false)`.
- Cleans up the event listener on component unmount to prevent memory leaks.
*/
  function useOutsideAlerter(ref: any) {
    useEffect(() => {
      function handleClickOutside(event: any) {
        if (ref.current && !ref.current.contains(event.target)) {
          setPosts([])
          setUsers([])
          setArticles([])
          props.setIsOpenSearch(false)
        }
      }
      document.addEventListener('mousedown', handleClickOutside)
      return () => {
        document.removeEventListener('mousedown', handleClickOutside)
      }
    }, [ref])
  }

  /* 
Uses the `useOutsideAlerter` hook with `wrapperRef` to detect clicks outside the wrapper:
- `wrapperRef` is passed to `useOutsideAlerter` to track the element that should trigger actions when clicked outside of.
- Ensures that clicking outside the referenced element triggers the clearing of search results and closing of the search modal.
*/
  const wrapperRef = useRef(null)
  useOutsideAlerter(wrapperRef)

  /* 
Handles view type change by updating the selected view, resetting the page to the first one,
and clearing previous search results for users, posts, and articles:
- `setViewType(newValue)` updates the selected view type (e.g., posts, users).
- `setPage('1')` resets the page number to 1 for the new view.
- Clears `users`, `posts`, and `articles` to prepare for the new search.
- `setValue('')` resets the search input value.
*/
  const handleChangeViewType = (event: any, newValue: any): void => {
    setViewType(newValue)
    setPage('1')
    setUsers([])
    setPosts([])
    setArticles([])
    setValue('')
  }

  const [, setPage] = useState<string>('1')
  const [loading, setLoading] = useState(false)
  const [posts, setPosts] = useState<Array<PostModel>>([])
  const [articles, setArticles] = useState<Array<ArticleModel>>([])
  const [users, setUsers] = useState<Array<ProfileModel>>([])

  /* 
Handles the search operation based on the provided text:
- `setLoading(true)` sets loading state to true while performing the search.
- `setValue(text)` updates the search input value.
- Clears `users`, `posts`, and `articles` to prepare for the new search results.
- If the search text is not empty:
  - Calls the `search` function to fetch search results.
  - Updates state with the fetched posts, articles, and users from the response.
  - Sets `setLoading(false)` when results are successfully fetched or an error occurs.
- If the search text is empty, stops the loading state by calling `setLoading(false)`.
*/
  /* 
Handles the search operation based on the provided text:
- `setLoading(true)` sets loading state to true while performing the search.
- `setValue(text)` updates the search input value.
- Clears `users`, `posts`, and `articles` to prepare for the new search results.
- If the search text is not empty:
  - Calls the `search` function to fetch search results.
  - Updates state with the fetched posts, articles, and users from the response.
  - Sets `setLoading(false)` when results are successfully fetched or an error occurs.
- If the search text is empty, stops the loading state by calling `setLoading(false)`.
*/
  const handleSearch = (text: string) => {
    setLoading(true)
    setValue(text)

    setUsers([])
    setPosts([])
    setArticles([])

    if (text !== '') {
      search(text)
        .then(result => {
          setPosts(result.data.posts)
          setArticles(result.data.articles)
          setUsers(result.data.users)

          setLoading(false)
        })
        .catch(error => {
          setLoading(false)
        })
    } else setLoading(false)
  }

  return (
    <Box $marginbottom={''} ref={wrapperRef} left={window.innerWidth}>
      {/* <MyInput
          placeholder={'Search'}
          label={''}
          value={value}
          onChange={handleSearch}
          name={'search'}
          icon={'search'}
          background={'gray70'}
          border={'gray60'}
          color={'white100'}
        /> */}
      <div className={'body'}>
        <div style={{ marginTop: '20px' }}></div>
        {loading ? (
          <Grid item md={12}>
            <CLoader width={50} height={50} />
          </Grid>
        ) : (
          <>
            {(users && users.length !== 0) ||
            (articles && articles.length !== 0) ||
            (posts && posts.length !== 0) ? (
              <>
                {users && users.length !== 0 && (
                  <>
                    <div className="title-box">
                      <div className="tag">
                        <div className="tag-before" />
                        <span>People</span>
                      </div>
                      <Link to={'posts/search/' + value} className={'view-all'}>
                        View All
                      </Link>
                    </div>
                    <div className="content-box">
                      {users.map((user, i) => (
                        <Grid item md={12} key={i}>
                          <SearchUserResultBox loading={loading} user={user} />
                        </Grid>
                      ))}
                    </div>
                  </>
                )}
                {articles && articles.length !== 0 && (
                  <>
                    <div className="title-box">
                      <div className="tag">
                        <div className="tag-before" />
                        <span>Articles</span>
                      </div>
                      <Link to={'posts/search/' + value} className={'view-all'}>
                        View All
                      </Link>
                    </div>
                    <div className="content-box">
                      {articles.map((article, i) => (
                        <Grid item md={12} key={i}>
                          <SearchArticleResultBox article={article} />
                        </Grid>
                      ))}
                    </div>
                  </>
                )}
                {posts && posts.length !== 0 && (
                  <>
                    <div className="title-box">
                      <div className="tag">
                        <div className="tag-before" />
                        <span>Posts</span>
                      </div>
                      <Link to={'posts/search/' + value} className={'view-all'}>
                        View All
                      </Link>
                    </div>
                    <div className="content-box">
                      {posts.map((post, i) => (
                        <Grid item md={12} key={i}>
                          <SearchPostResultBox post={post} />
                        </Grid>
                      ))}
                    </div>
                  </>
                )}
              </>
            ) : (
              <>
                <Grid item md={12}>
                  <NothingFound
                    icon={'person_search'}
                    title={'No Item Found'}
                  />
                </Grid>
              </>
            )}
          </>
        )}
      </div>
    </Box>
  )
}
