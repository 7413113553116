import React, { useEffect, useState } from 'react'
import { Page } from '../../../../components/structure'
import RoomBox from '../../../../components/chat/create-room/box'
import UserList from '../../../../components/chat/create-room/userList'
import { ProfileModel } from '../../../../models/profile.model'
import { useNavigate } from 'react-router-dom'
import { isDesktop } from '../../../../utils/detect-screen'

type Props = {}

const MobileRoom = (props: Props) => {
  const navigate = useNavigate()
  /* 
Navigates to the home page when the component mounts on a desktop device:
- Checks if the current device is a desktop using the `isDesktop()` function.
- If true, it redirects the user to the home page (`/`) using `navigate`.
- This effect runs only once on component mount due to the empty dependency array (`[]`).
*/
  useEffect(() => {
    if (isDesktop()) {
      navigate('/')
    }
  }, [])
  const [searchUser, setSearchUser] = useState<'noUser' | 'search'>('noUser')
  const [users, setUsers] = useState<Array<ProfileModel>>([])
  return (
    <Page title="Personia" sidebar={<></>} sidebar2={<></>} mainHeight="650px">
      {searchUser === 'search' ? (
        <UserList
          setSearchUser={setSearchUser}
          setAddedUsers={setUsers}
          addedUsers={users}
        />
      ) : null}
      {searchUser === 'noUser' ? (
        <RoomBox
          setSearchUser={setSearchUser}
          setUserList={setUsers}
          usersList={users}
        />
      ) : null}
    </Page>
  )
}

export default MobileRoom
