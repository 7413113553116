import { useAppSelector } from '../../redux/hooks'
import React, { ReactElement, useEffect, useState } from 'react'
import { Page } from '../../components/structure'
import { ProfileBox } from '../../components/profile'
import styled from 'styled-components'
import { NothingFound } from '../../components/custom'
import { StartupBox } from '../../components/startup'
import { StartupProfileModel } from '../../models/startup/startup-profile.model'
import { PlatformProfileModel } from '../../models/platform/platform-profile.model.'
import { findAllStartups } from './../../apis/startup.apis'
import { findAllPlatforms } from './../../apis/platform.apis'
import InfiniteScroll from 'react-infinite-scroll-component'

const Box = styled.div`
  padding: 16px;
  background: ${props => props.theme.navy80};
  border-radius: 16px;
`

export function StartupsPage(): ReactElement {
  const [startUps, setStartups] = useState<Array<StartupProfileModel>>([])
  const [platforms, setPlatforms] = useState<Array<PlatformProfileModel>>([])
  const [cursor, setCursor] = useState<string>('')
  const [startupLoading, setStartupLoading] = useState<boolean>(true)
  const [hasMore, setHasMore] = useState<boolean>(true)

  useEffect(() => {
    fetchProfiles(cursor)
  }, [])

  /* 
Fetches startup profiles and platforms:
- Loads more startups if available, updating the cursor.
- Fetches platforms only once if not already loaded.
- Handles loading state and errors appropriately.
*/
  const fetchProfiles = async (_cursor: string) => {
    try {
      setStartupLoading(true)

      const startupRequest = {
        search: {
          profileIDs: [''],
          q: '',
        },
        cursor: _cursor,
        numberPerPage: 5,
      }

      const startupResult = await findAllStartups(startupRequest)

      if (startupResult.data.startups.length === 0) {
        setHasMore(false)
      } else {
        setStartups(prevStartups => [
          ...prevStartups,
          ...startupResult.data.startups,
        ])
        setCursor(startupResult.data.cursor)
      }

      if (platforms.length === 0) {
        const platformRequest = {
          search: {
            profileIDs: [''],
            q: '',
          },
          cursor: '',
          numberPerPage: 30,
        }

        const platformResult = await findAllPlatforms(platformRequest)
        setPlatforms(platformResult.data.platforms)
      }

      setStartupLoading(false)
    } catch (error) {
      setHasMore(false)
      setStartupLoading(false)
      console.error('Error fetching profiles:', error)
    }
  }

  return (
    <Page title="Startups" sidebar={<ProfileBox />} sidebar2={null}>
      <Box>
        <div id="scrollableDiv">
          {startupLoading && startUps.length === 0 ? (
            [1, 2, 3, 4].map(i => <StartupBox loading={true} key={i} />)
          ) : startUps.length === 0 ? (
            <NothingFound
              icon="hourglass_disabled"
              title="No Startup Found"
              padding="30px"
            />
          ) : (
            <InfiniteScroll
              dataLength={startUps.length}
              next={() => fetchProfiles(cursor)}
              hasMore={hasMore}
              loader={<StartupBox loading={true} />}
              endMessage={
                <p
                  style={{
                    textAlign: 'center',
                    color: 'white',
                    padding: '20px',
                    background: '#201a31',
                  }}
                >
                  <b>No More Startups</b>
                </p>
              }
              scrollableTarget="scrollableDiv"
            >
              {[...startUps]
                .sort(
                  (x: any, y: any) =>
                    new Date(y.createdAt).getTime() -
                    new Date(x.createdAt).getTime()
                )
                .map((startup, i) => (
                  <StartupBox
                    loading={false}
                    startup={startup}
                    platform={
                      platforms.find(item => item.id === startup.platformID) ||
                      null
                    }
                    key={i}
                  />
                ))}
            </InfiniteScroll>
          )}
        </div>
      </Box>
    </Page>
  )
}

