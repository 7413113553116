import React, { ReactElement, useContext, useEffect, useState } from 'react'
import { useLocation, useParams } from 'react-router-dom'
import { encryptionService, NotificationService } from '../../../services'
import { useAppSelector } from '../../../redux/hooks'
import { PasswordPrompt, Room, Shell } from '../../../components/room'
import { LoginButton } from '../../../components/auth'
export function PrivateChatRoomPage(props: {
  currentWindow?: Window
}): ReactElement {
  const { id } = useParams()
  const user = useAppSelector(state => state.user)
  const location = useLocation()
  const [secret, setSecret] = useState('')
  const [openModel, setOpenModal] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)

  /* 
Extracts URL parameters from the hash and requests notification permissions:
- Retrieves the URL parameters from the hash section of the current URL (`window.location.hash`).
- Calls `NotificationService.requestPermission` to request permission to show notifications when the component mounts.
- This effect runs only once on component mount due to the empty dependency array (`[]`).
*/
  const urlParams = new URLSearchParams(window.location.hash.substring(1))
  useEffect(() => {
    NotificationService.requestPermission()
  }, [])

  /* 
Handles password and secret initialization on component mount:
- Checks if `location.state` contains a password and encodes it, or retrieves the secret from URL parameters if available.
- If no password or secret is found, opens a modal (`setOpenModal(true)`).
- This effect runs whenever the `location` object changes, ensuring that the password and secret are correctly set based on the current location state or URL.
*/
  useEffect(() => {
    ;(async () => {
      if (location.state) {
        setPassword(location.state.password)
        setSecret(
          await encryptionService.encodePassword(id, location.state.password)
        )
      } else if (urlParams.get('secret') !== null) {
        // console.log(props.currentWindow)
        setSecret(
          await encryptionService.encodePassword(id, urlParams.get('secret'))
        )
      } else {
        setOpenModal(true)
      }
    })()
  }, [location])

  const [password, setPassword] = useState('')

  /* 
Handles the password entered by the user:
- If the password is not empty, it updates the `password` state, encodes the password using `encryptionService`, and sets the `secret`.
- Closes the modal (`setOpenModal(false)`) once the password is successfully processed.
*/
  const handlePasswordEntered = async (password: string) => {
    if (password.length !== 0) {
      setPassword(password)
      setSecret(await encryptionService.encodePassword(id, password))
      setOpenModal(false)
    }
  }

  /* 
Opens the modal if the user's `did` is an empty string:
- Checks if `user.did` is empty and, if true, sets `isModalOpen` to true, triggering the modal to open.
- This effect runs only once on component mount due to the empty dependency array (`[]`).
*/
  useEffect(() => {
    if (user.did == '') {
      setIsModalOpen(true)
    }
  }, [])

  return (
    <Shell appNeedsUpdate={false} userPeerId={user.id}>
      {secret.length > 0 ? (
        <Room
          currentWindow={props.currentWindow}
          roomId={id}
          userId={user.id}
          password={secret}
          rPass={password}
        />
      ) : (
        <PasswordPrompt
          isOpen={openModel}
          onPasswordEntered={handlePasswordEntered}
        />
      )}
    </Shell>
  )
  // }
  // else {
  //   return (
  //     <>
  //       <LoginButton
  //         isOpen={isModalOpen}
  //         color={''}
  //         textcolor={''}
  //         size={'s'}
  //       />
  //     </>
  //   )

  // }
}
