import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { breakpoints } from '../../config/global-styles'
import { MyInput } from '../custom/input'
import { CButton, CSkeleton } from '../mui'
import { ProfileModel } from '../../models/profile.model'
import { useAppSelector } from '../../redux/hooks'
import { SearchAllUsers } from '../../apis/user.api'
import { ReactComponent as ArrowLeft } from '../../assets/svg/arrow-left.svg'
import { UserInfoBox } from './userInfoBox'
import InfiniteScroll from 'react-infinite-scroll-component'

type Props = {
  onBack: () => void
  setSearchUsersModal: (searchUserModal?: any) => void
}

export interface StyledInputType {
  $background: string
  right: number
}

const ChatBoxStyle = styled.div<StyledInputType>`
  padding: 8px 16px 8px 16px;
  background: ${({ theme, $background }) => theme[$background]};
  border: 0.5px solid ${props => props.theme.gray60};
  width: 350px;
  border-radius: 16px 16px 0 0;
  position: absolute;
  /* right: ${({ right }) => right}; */
  right: 0px;
  bottom: 0;
  box-shadow: -1px -1px 4px 0 rgba(255, 255, 255, 0.1);
  z-index: 40;
  @media only screen and (max-width: ${breakpoints.tablet}) {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    /* min-height: 766px; */
    position: static;
  }

  @media only screen and (min-width: ${breakpoints.minDesktop}) and (max-width: ${breakpoints.maxDesktop}) {
    width: 300px;
  }

  > .header {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 8px 0px;
    justify-content: space-between;
    position: relative;
    > .icon {
      cursor: pointer;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
    }

    > p {
      font-size: 16px;
      font-weight: 500;
      font-family: Inter;
      color: ${props => props.theme.black100};
      text-align: justify;
      flex: 1;
      text-align: center;
      @media only screen and (max-width: ${breakpoints.tablet}) {
        text-align: center;
        height: 56px;
      }

      &.green {
        color: ${props => props.theme.green100};
      }
    }

    > .material-symbols-outlined {
      color: ${props => props.theme.black100};
      cursor: pointer;
      font-size: 28px;

      &.green {
        color: ${props => props.theme.green100};
      }
    }
  }

  > .body {
    /* height: 504px; */
    @media only screen and (max-width: ${breakpoints.tablet}) {
      height: 530px;
    }
    overflow: auto;

    .search-and-add {
      width: 100%;
      display: flex;
      align-items: center;
      gap: '10px';
      > .search {
        flex-basis: 100%;
      }
    }

    > .users {
      margin-top: 15px;

      > .row {
        display: flex;
        padding-bottom: 15px;
        padding-top: 15px;
        border-bottom: 1px solid ${props => props.theme.gray90};
        width: 100%;
        cursor: pointer;

        > .column {
          width: 170px;
          margin-left: 10px;

          > p {
            font-size: 14px;
            font-weight: 400;
            font-family: Inter;
            color: ${props => props.theme.white100};
            text-align: left;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
          }
        }

        > p {
          font-size: 14px;
          font-weight: 400;
          font-family: Inter;
          color: ${props => props.theme.white100};
          text-align: right;
          align-self: center;
          margin-left: 10px;
          width: 40px;
        }

        > span {
          font-size: 22px;
          color: ${props => props.theme.white100};
          align-self: center;
          margin-left: 5px;
        }
      }

      > .empty-row {
        display: flex;
        padding-bottom: 15px;
        padding-top: 15px;
        width: 100%;
        cursor: pointer;
        > p.empty {
          font-size: 14px;
          font-weight: 400;
          font-family: Inter;
          color: ${props => props.theme.white100};
          text-align: center;
          align-self: center;
          margin-left: 0px;
          width: 100%;
          opacity: 50%;
          line-height: 22px;
        }
      }
      > .loadMore {
        width: 95%;
        height: 40px;
        background: #140e26;
        border-radius: 8px;
        color: white;
        border: none;
        margin-top: 15px;
        margin-bottom: 15px;
      }
    }
  }
`

const ChatUsersSearchModal = (props: Props) => {
  const [loading, setLoading] = useState<boolean>(false)
  const [users, setUsers] = useState<Array<ProfileModel>>([])
  const [cursor, setCursor] = useState<string>('')
  const [numPerPage, setNumPerPage] = useState<number>(10)
  const [hasMore, setHasMore] = useState<boolean>(true)

  const [searchText, setSearchText] = useState<string>('')

  /* 
Fetches a paginated list of users based on the current search text:
- Sets the loading state to true while fetching the users.
- Sends a search request with the search query, cursor, and pagination limit.
- Appends the fetched users to the existing users list and updates the cursor for the next page.
- Resets the loading state after the request is complete or if there's an error.
*/
  const getUsers = (_cursor: string) => {
    console.log(_cursor, '_cursor')
    if (loading) return
    setLoading(true)
    SearchAllUsers({
      q: searchText,
      cursor: _cursor,
      perPage: 10,
    })
      .then(res => {
        if (res.data.users.length === 0) {
          setHasMore(false)
        }
        // if(users.length !==res.data.users.length){
        setUsers(prevUsers => [...prevUsers, ...res.data.users])
        setCursor(res.data.cursor)
        console.log(cursor, 'cursor')
        setLoading(false)
      })
      .catch(err => {
        setLoading(false)
        setHasMore(false)
      })
  }

  /* 
Resets the users list and updates the search text state:
- Clears the existing users list and sets the `searchText` state to the provided input value.
*/
  const searchUser = (txt: string = '') => {
    setUsers([])
    setSearchText(txt)
    setCursor('')
    setHasMore(true)
  }

  /* 
Fetches a new list of users whenever the search text changes:
- Resets the `users` list and triggers `getUsers` to fetch updated user data based on the latest `searchText`.
*/
  useEffect(() => {
    getUsers('')
  }, [searchText])

  return (
    <ChatBoxStyle $background={'navy60'} right={window.innerWidth}>
      <div className={'header'}>
        <div className={'icon'} onClick={props.onBack}>
          <ArrowLeft />
        </div>
        <p className={'green'}>{'Search Profile'}</p>
      </div>

      <div className={'body'}>
        <div className="search-and-add">
          <div className="search">
            <MyInput
              placeholder={'Search'}
              label={''}
              name={'search'}
              icon={'search'}
              background={'gray70'}
              border={'gray60'}
              onChange={searchUser}
              color={'white100'}
            />
          </div>
        </div>
        <div className={'users'}>
          <InfiniteScroll
            dataLength={users.length}
            next={() => getUsers(cursor)}
            hasMore={hasMore}
            loader={
              <p style={{ textAlign: 'center', color: 'white' }}>Loading...</p>
            }
            height="460px"
            endMessage={
              <p style={{ textAlign: 'center', color: 'white' }}>
                <b>No more users</b>
              </p>
            }
            scrollableTarget="scrollableDiv"
          >
            {users.map((userItem, index) => (
              <UserInfoBox
                user={userItem}
                key={index}
                setSearchUsersModal={props.setSearchUsersModal}
              />
            ))}
          </InfiniteScroll>
          {users.length === 0 && !loading && (
            <div className="empty-row">
              <p className="empty">
                Sorry! no user with this profile info was found.
              </p>
            </div>
          )}
        </div>
      </div>
    </ChatBoxStyle>
  )
}

export default ChatUsersSearchModal
