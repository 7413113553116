import React, { useContext, useEffect } from 'react'
import styled from 'styled-components'
import { CButton } from '../mui'
import { ReactComponent as DangerAlert } from '../../assets/svg/danger.svg'
import { AlertContext } from '../../contexts/alert-context'
const Box = styled.div`
  display: flex;
  width: 383px;
  flex-direction: column;
  align-items: flex-end;
  height: 90px;
  border: 1px solid #d5ccf3;
  border-radius: 16px;
  background: #140e26;
  margin: 5px 0px;

  .top-alert {
    width: 100%;
    border-radius: 16px 16px 0px 0px;
    display: flex;
    justify-content: space-between;
    padding: 16px 8px;
    background-color: #a8071a;
    height: 40px;
    > div:nth-of-type(1) {
      display: flex;
      align-items: center;
      > div {
        color: white;
      }
    }
    > div:nth-of-type(2) {
      display: flex;
      align-items: center;
      cursor: pointer;
    }
  }
  .body {
    width: 100%;
    height: 100%;
    display: flex;
    padding: 10px 12px;
    justify-content: center;
    align-items: center;
    gap: 10px;
    > div:first-of-type {
      color: white;
      font-size: 14px;
    }
    > div:last-of-type {
      width: 100%;
      display: flex;
      color: white;
      font-size: 14px;
      font-weight: 400;
      line-height: 22px;
    }
  }
`

interface FieldsErrorAlertProps {
  showAlert: boolean
  setShowAlert: (value: boolean) => void
}


/* 
Renders an alert when there's a network error or missing required fields:
- The component uses the `AlertContext` to get the `showAlert` value, which controls whether the alert is visible.
- If `showAlert` is `false`, the component returns `null` and does not render anything.
- If `showAlert` is `true`, it displays a fixed-position alert at the bottom left of the screen.
- The alert consists of two main parts:
  - A header with a "Network Error" message.
  - A body with a `DangerAlert` component and a message instructing the user to fill all required fields.
- The alert has a `zIndex` of 20 to ensure it appears above other content on the page.
*/
export default function FieldsErrorAlert() {
  const alertContext = useContext(AlertContext)
  if (!alertContext) {
    throw new Error('FieldsErrorAlert must be used within an AlertProvider')
  }

  const { showAlert } = alertContext

  if (!showAlert) return null
  return (
    <div
      style={{
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        position: 'fixed',
        zIndex: '20',
        left: '10px',
        bottom: '10px',
      }}
    >
      <Box>
        <div className="top-alert">
          <div>
            <div>Network Error</div>
          </div>
        </div>
        <div className="body">
          <div>
            <DangerAlert />
          </div>
          <div>Please fill all the required fields.</div>
        </div>
      </Box>
    </div>
  )
}
