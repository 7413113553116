import { useAppSelector } from '../../redux/hooks'
import React, { ReactElement, useEffect, useState } from 'react'
import { Page } from '../../components/structure'
import { ProfileBox } from '../../components/profile'
import styled from 'styled-components'
import { NothingFound } from '../../components/custom'
import { PlatformBox } from '../../components/platform'
import { PlatformProfileModel } from '../../models/platform/platform-profile.model.'
import { findAllPlatforms } from '../../apis/platform.apis'
import InfiniteScroll from 'react-infinite-scroll-component';

const Box = styled.div`
  padding: 16px;
  background: ${props => props.theme.navy80};
  border-radius: 16px;
`


export function PlatformsPage(): ReactElement {
  const [platforms, setPlatforms] = useState<Array<PlatformProfileModel>>([]);
  const [cursor, setCursor] = useState<string>('');
  const [platformLoading, setPlatformLoading] = useState<boolean>(true);
  const [hasMore, setHasMore] = useState<boolean>(true);

  useEffect(() => {
    fetchPlatforms(cursor);
  }, []);

  const fetchPlatforms = (_cursor: string) => {
    setPlatformLoading(true);
    findAllPlatforms({ cursor: _cursor, numberPerPage: 5, search: {} })
      .then(result => {
        setPlatformLoading(false);
        if (result.data.platforms.length === 0) {
          setHasMore(false);
          return;
        }
        setPlatforms(prevPlatforms => [...prevPlatforms, ...result.data.platforms]);
        setCursor(result.data.cursor);
      })
      .catch(error => {
        setPlatformLoading(false);
        setHasMore(false);
      });
  };

  return (
    <Page
      title="Platforms"
      sidebar={<ProfileBox />}
      sidebar2={null}
    >
      <Box>
        <div id="scrollableDiv">
          {platformLoading && platforms.length === 0 ? (
            [1, 2, 3, 4].map(i => <PlatformBox loading={true} key={i} />)
          ) : platforms.length === 0 ? (
            <NothingFound
              icon="hourglass_disabled"
              title="No Platform Found"
              padding="30px"
            />
          ) : (
            <InfiniteScroll
              dataLength={platforms.length}
              next={() => fetchPlatforms(cursor)}
              hasMore={hasMore}
              loader={<PlatformBox loading={true} />}
              endMessage={
                <p style={{ textAlign: 'center', color: 'white', padding: '20px', background: '#201a31' }}>
                  <b>No More Platforms</b>
                </p>
              }
              scrollableTarget="scrollableDiv"
            >
              {[...platforms]
                .sort(
                  (x: any, y: any) =>
                    new Date(y.createdAt).getTime() - new Date(x.createdAt).getTime()
                )
                .map((platform, i) => (
                  <PlatformBox platform={platform} key={i} />
                ))}
            </InfiniteScroll>
          )}
        </div>
      </Box>
    </Page>
  );
}





