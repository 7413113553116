import React from 'react'
import styled from 'styled-components'
import { PlatformProfileModel } from '../../../models/platform/platform-profile.model.'
import { StartupProfileModel } from '../../../models/startup/startup-profile.model'
import { breakpoints } from '../../../config/global-styles'

const PlatformDataStyle = styled.div<{ textcolor: string }>`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: space-around;
  align-items: center;
  @media only screen and (min-width: ${breakpoints.mobile}) and (max-width: ${breakpoints.tablet}) {
    > * {
      color: rgba(255, 255, 255, 0.85);
      font-size: 14px;
      font-style: normal;
      font-weight: 400;
      line-height: 22px;
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }
  }
  > * {
    color: rgba(255, 255, 255, 0.85);
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
  }
`


export interface Props {
  platform: PlatformProfileModel
  startup: StartupProfileModel
}

/* 
Displays the platform name associated with a startup if the platform exists. 
Renders nothing if the platform is undefined or null.
*/
export function PlatformData(props: Props) {
  const { platform } = props

  return (
    <>
      <PlatformDataStyle textcolor={'white100'}>
        <p>
          <span>{platform?.companySize} </span>
          <span>Team member</span>
        </p>
        <p style={{ marginLeft: '15px' }}>
          <span>{platform?.platformCount} 10</span>
          <span style={{ marginLeft: '5px' }}>Platforms</span>
        </p>
      </PlatformDataStyle>
    </>
  )
}
