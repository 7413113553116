import { useEffect, useState } from "react";
import { ThemeProvider } from "styled-components";
import GlobalStyles, { LightTheme, DarkTheme } from "./config/global-styles";
import Routes from "./routes";
import { AlertColor } from "@mui/material";
import { GlobalContext, CeramicWrapper } from "./contexts";
import { CSnackbar } from "./components/mui/Snackbar";
import { NotificationContext, NotificationProvider } from "./contexts/notification-context";
import Notification from "./components/structure/notification";
import { AlertProvider } from "./contexts/alert-context";

// This is the main App component for a React application. It manages the a
// pplication theme (light or dark) and handles alert notifications using
// state management. The ThemeProvider from styled-components is used to
// apply the selected theme globally. The GlobalContext provides access to
// the theme and alert functions throughout the application. The CSnackbar
// component displays alerts based on the current state, and the CeramicWrapper
// encapsulates the routing logic. Overall, this component sets up the foundational
// structure for theming and notifications in the app.
function App() {
  const [theme, setTheme] = useState('light')

  const [alert, setAlert] = useState<{
    type: AlertColor | undefined
    message: string
  }>({ message: '', type: 'success' })

  function makeAlert(type: AlertColor, message: string): void {
    setAlert({ type, message })
  }

  return (
    <ThemeProvider theme={theme === 'light' ? LightTheme : DarkTheme}>
      <>
        <GlobalStyles />

        <GlobalContext.Provider
          value={{
            theme,
            setTheme,
            makeAlert,
          }}
        >
          <AlertProvider>
            <NotificationProvider>
              <CeramicWrapper>
                <Routes />
              </CeramicWrapper>
              <CSnackbar
                open={alert.message !== ''}
                handleClose={() => {
                  makeAlert('success', '')
                }}
                severity={alert.type}
                label={alert.message}
              />
            </NotificationProvider>
          </AlertProvider>
        </GlobalContext.Provider>
      </>
    </ThemeProvider>
  )
}

export default App
